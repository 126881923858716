import React from 'react'
import { Center } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

const NavButton = (props) => {
	const [hover, setHover] = useState(false);
	const activeStyle = {
		backgroundColor: "rgba(0,0,0,0.15)",
		padding: "16px 20px",
		width: props.mobile && "100%",
		textAlign: props.mobile && "right",
	}
	const hoverStyle = {
		backgroundColor: "rgba(0,0,0,0.1)",
		padding: "16px 20px",
		width: props.mobile && "100%",
		textAlign: props.mobile && "right",
	}
	const regularStyle = {
		backgroundColor: "white",
		padding: "16px 20px",
		width: props.mobile && "100%",
		textAlign: props.mobile && "right",
	}
	return (
		<Center fontSize="20px">
			<NavLink
				style={({ isActive }) =>
					isActive ? activeStyle : hover ? hoverStyle : regularStyle
				}
				to={props.link}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				{props.children}
			</NavLink>
		</Center>
	)
}
export default NavButton;
