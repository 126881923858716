import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Book from './components/Book';
import Contact from './components/Contact';
import Home from './components/Home';
import Press from './components/Press';



function App() {
    return (
        <Router>
            <Routes>
                <Route path="/book" element={<Book />} />
                <Route path="/press" element={<Press />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<Home />} />
                <Route path="*" element={<Navigate replace to="/about" />} />
            </Routes>
        </Router>
    );
}

export default App;
