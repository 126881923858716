import { Button, Center, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, SimpleGrid, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { GoogleFormProvider, useGoogleForm, useLongAnswerInput, useRadioInput, useShortAnswerInput } from 'react-google-forms-hooks';
import AutoResizeTextArea from './AutoResizeTextArea';
import form from "./form.json";

import PageLayout from './PageLayout';


const ShortAnswerInput = ({ id }) => {
    const { register, label } = useShortAnswerInput(id);
    return (
        <FormControl isRequired>
            <FormLabel htmlFor={label}>{label}</FormLabel>
            <Input type="text" {...register()} />
        </FormControl>
    )
}

const RadioInput = ({ id }) => {
    const { options } = useRadioInput(id)
    return (
        <RadioGroup id="purpose" name="purpose">
            <Stack spacing={3} direction="column">
                {options.map((option) => (
                    <Radio key={option.id} id={option.id} {...option.registerOption()}>{option.label}</Radio>
                ))}
            </Stack>
        </RadioGroup>
    )
}

const LongAnswerInput = ({ id }) => {
    const { register, label } = useLongAnswerInput(id);
    return (
        <FormControl isRequired>
            <FormLabel htmlFor={label}>{label}</FormLabel>
            <AutoResizeTextArea minH={200} name={label} {...register()} />
        </FormControl>
    )
}

const Contact = () => {
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const methods = useGoogleForm({ form });
    const onSubmit = async (data) => {
        setLoading(true)
        await methods.submitToGoogleForms(data);
        methods.reset();
        setLoading(false)
        setSubmitted(true)
    }

    // Handles form setup
    /*
    useEffect(() => {
        (async () => {
            // can use both full and shortened form url
            const result = await googleFormsToJson(
                'https://docs.google.com/forms/d/e/1FAIpQLSdfnXUFpT2jIGmVmTQu7YtaCyKXJkqp_Mi-7fS4Z5yWcdO_vw/viewform?usp=sf_link'
            )
            console.log(JSON.stringify(result))
        })()
    }, [])
    */

    return (
        <PageLayout>
            <Heading>
                Get in Touch
            </Heading>
            <Center mt="12px">
                Email me with any general questions about the book.
            </Center>
            <GoogleFormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <SimpleGrid minChildWidth="120px" spacing="20px">
                        <ShortAnswerInput id="366993140" />
                        <ShortAnswerInput id="401504990" />
                        <ShortAnswerInput id="316886909" />
                    </SimpleGrid>
                    <FormLabel htmlFor="purpose" mt="8px">I am looking to...</FormLabel>
                    <Center>
                        <RadioInput id="912727543" />
                    </Center>
                    <LongAnswerInput id="1439590816" />
                    <Center mt="8px">
                        <Button type='submit' isLoading={loading} isDisabled={submitted}>Submit</Button>
                    </Center>
                </form>
                {
                    submitted && (
                        <Center mt="16px">
                            <Heading color="#4BB543" size="md">
                                Thank you for your submission!
                            </Heading>
                        </Center>
                    )
                }
            </GoogleFormProvider>
        </PageLayout >
    )
}
export default Contact;
