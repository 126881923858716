import React from 'react'
import PageLayout from "./PageLayout";
import { Text, VStack, Link } from "@chakra-ui/react"

const Thing = ({ children }) => {
	return (
		<VStack spacing="2px">
			{children}
		</VStack>
	)
}
const ThingContext = ({ children }) => {
	return (
		<Text>
			{children}
		</Text>
	)
}
const ThingLink = ({ children, url }) => {
	return (
		<Link href={url} isExternal style={{ textDecoration: "underline" }}>
			{children}
		</Link>
	)
}

const Press = () => {
	return (
		<PageLayout>
			<VStack spacing="16px">
				<Thing>
					<ThingContext>
						Dec 5, 2022, Curious Minds at Work
					</ThingContext>
					<ThingLink url="https://www.gayleallen.net/cm-228-woo-kyoung-ahn-on-thinking-smarter/">
						Curious Minds at Work; Woo-kyoung Ahn on Thinking Smarter
					</ThingLink>
				</Thing>
				<Thing>
					<ThingContext>
						Oct 25, 2022, Mind Love
					</ThingContext>
					<ThingLink url="https://www.mindlove.com/podcast/science-thinking-reasoning-better/">
						Mind Love Podcast
					</ThingLink>
				</Thing>
				<Thing>
					<ThingContext>
						Oct 24, 2022, Retirement Wisdom
					</ThingContext>
					<ThingLink url="https://www.retirementwisdom.com/podcasts/thinking-better-to-live-better-dr-woo-kyoung-ahn/">
						Thinking Better to Live Better – Dr. Woo-kyoung Ahn
					</ThingLink>
				</Thing>
				<Thing>
					<ThingContext>
						Oct 11, 2022, Edutopia
					</ThingContext>
					<ThingLink url="https://www.edutopia.org/article/getting-fast-thinkers-slow-down">
						Getting Fast Thinkers to Slow Down
					</ThingLink>
				</Thing>

				<Thing>
					<ThingContext>
						Oct 5, 2022, Yale News
					</ThingContext>
					<ThingLink url="https://news.yale.edu/2022/10/05/bias-brain-yale-psychologist-examines-common-thinking-problems">
						Bias on the brain: A Yale psychologist examines common ‘thinking problems’
					</ThingLink>
				</Thing>

				<Thing>
					<ThingContext>
						Sept 23, 2022, You could be better at thinking in daily life, here’s how
					</ThingContext>
					<ThingLink url="https://think.kera.org/2022/09/22/you-could-be-better-at-thinking-in-daily-life-heres-how/">
						KERA, Think; Texas Public Radio
					</ThingLink>
				</Thing>

				<Thing>
					<ThingContext>
						Sept 15, 2022, NPR Life Kit
					</ThingContext>
					<ThingLink url="https://www.npr.org/2022/09/13/1122660697/3-common-thinking-traps-and-how-to-avoid-them-according-to-a-yale-psychologist">
						3 common thinking traps and how to avoid them, according to a Yale psychologist
					</ThingLink>
				</Thing>

				<Thing>
					<ThingContext>
						August 18, 2022, Armchair Expert with Dax Shepard
					</ThingContext>
					<ThingLink url="https://armchairexpertpod.com/pods/woo-kyoung-ahn">
						Armchair Expert with Dax Shepard
					</ThingLink>
				</Thing>
			</VStack>
		</PageLayout>
	)
}
export default Press;
