import React from 'react'
import { HStack, Text, VStack, Image, Spacer, Link } from '@chakra-ui/react';
import PageLayout from './PageLayout';
import wooKyoungAhn from '../assets/woo-kyoung-ahn.jpeg';

const Home = () => {
	return (
		<PageLayout>
			<HStack>
				<VStack>
					<Text>
						Woo-kyoung Ahn is the John Hay Whitney
						Professor of Psychology and the director
						of <Link href='https://ahnthinkinglab.yale.edu/papers' isExternal textDecoration="underline">Thinking Lab</Link> at Yale University.
					</Text>

					<Text>
						Dr. Ahn’s main area of research interest is
						higher-level reasoning processes, such as
						how people learn and represent concepts
						and causal relations, and how causal
						explanations shape our thinking
						processes. She studies basic cognitive
						processes underlying concept and causal
						learning as well as applied issues, such as
						how expert clinicians’ causal explanations
						for mental disorders affect their
						diagnoses, and how learning about one’s
						genetic predisposition affects people’s
						expectations about their symptoms.

					</Text>
					<Text>
						For more about her research, <Link href='https://ahnthinkinglab.yale.edu/papers' isExternal textDecoration="underline">click here</Link>.
					</Text>
				</VStack>
				<Spacer />
				<Image src={wooKyoungAhn} alt="Woo-kyoung Ahn" maxW="40%" />
			</HStack>
		</PageLayout>
	)
}
export default Home;
