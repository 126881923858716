import React from 'react'
import { Flex, Center, Spacer } from '@chakra-ui/react';
import { useWindowWidth } from '@react-hook/window-size';
import { slide as Menu } from 'react-burger-menu'

import NavButton from './NavButton';
import SoundButton from './SoundButton';

const links = [
	{
		name: "About",
		link: "/about",
	},
	{
		name: "The Book",
		link: "/book",
	},
	{
		name: "Press",
		link: "/press",
	},
	{
		name: "Contact",
		link: "/contact",
	},
];
const NavBar = () => {
	const width = useWindowWidth();
	const mobile = width <= 500;
	if (mobile) {
		return (
			<>
				<Center w='100%'>
					<SoundButton fontSize="20px" iconSize="24px">Woo-kyoung Ahn</SoundButton>
				</Center>
				<Menu style={{ zIndex: 1 }} right>
					{links.map((link) => (
						<NavButton key={link.name} link={link.link} mobile>
							{link.name}
						</NavButton>
					))}
				</Menu>
			</>
		)
	}
	else {
		return (
			<Flex w='100%'>
				<SoundButton>Woo-kyoung Ahn</SoundButton>
				<Spacer />
				{links.map((link) => (
					<NavButton key={link.name} link={link.link}>
						{link.name}
					</NavButton>
				))}
			</Flex>
		);
	}
}
export default NavBar;

