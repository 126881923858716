import React from 'react'
import PageLayout from "./PageLayout";
import { Heading, Text, VStack, HStack, Image, Link, Box, Flex } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import book from '../assets/thinking101.jpeg';

const Author = ({ children }) => {
	return (
		<VStack spacing="8px">
			{children}
		</VStack>
	)
}
const AuthorQuote = ({ children }) => {
	return (
		<Text>
			{children}
		</Text>
	)
}
const AuthorAuthor = ({ children }) => {
	return (
		<Flex w="100%" alignContent="flex-end" justifyContent="right">
			<Text textAlign="right" maxW="75%" as="b">
				{children}
			</Text>
		</Flex>
	)
}
const Book = () => {
	return (
		<PageLayout>
			<VStack spacing="32px">
				<HStack spacing="24px">
					<Image src={book} alt="Thinking 101 Book Cover" w="50%" />
					<Heading size="md" textAlign="center">
						<Link href="https://us.macmillan.com/books/9781250805959/thinking101" isExternal style={{ textDecoration: "none" }}>
							Order Your Copy Today!
							<ExternalLinkIcon mx='2px' />
						</Link>
					</Heading>
				</HStack>
				<Box>
					<VStack>
						<Heading size="lg">Book Details from the publisher’s page:</Heading>
						<Text>
							Psychologist Woo-kyoung Ahn devised a course at Yale called “Thinking” to help students examine the biases that cause so many problems in their daily lives. It quickly became one of the university’s most popular courses. In Ahn’s class, students examine “thinking problems”—like confirmation bias, causal attribution, and delayed gratification—and how they contribute to our most pressing societal issues and inequities. Now, for the first time, Ahn presents key insights from her years of teaching and research in a book for everyone.
						</Text>
						<Text>
							Ahn draws on decades of research from other cognitive psychologists, as well as from her own groundbreaking studies. And she presents it all in a compellingly readable style that uses fun examples from K-pop dancing, anecdotes from her own life, and illuminating stories from history and the headlines.
						</Text>
						<Text>
							Thinking 101 is a book that goes far beyond other books on thinking, showing how we can improve not just our own daily lives and tackle real-world problems through better awareness of our biases but also the lives of everyone around us. It is, quite simply, required reading for everyone who wants to think—and live—better.
						</Text>
					</VStack>
				</Box>

				<Box>
					<VStack spacing="32px">
						<Heading size="lg">Praise for Thinking 101</Heading>
						<Author>
							<AuthorQuote>
								“This book is not just a lucid overview of the cognitive traps that wreak havoc on your reasoning―it’s also an expert's guide to rethinking how we think.”
							</AuthorQuote>
							<AuthorAuthor>
								―Adam Grant, #1 New York Times bestselling author of Think Again
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								"Thinking 101 combines the best science with practical advice to help you make better decisions. Ahn’s stories are spot-on, they are humorous, and they show us how thinking can be turned on itself to overcome the biases from, well, thinking!"
							</AuthorQuote>
							<AuthorAuthor>
								―Mahzarin Banaji, Professor of Psychology, Harvard University and co-author of Blindspot: Hidden Biases of Good People
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								"Every day of our lives, we make judgments―and we don’t always do a very good job of it. Thinking 101 is an invaluable resource to anyone who wants to think better. In remarkably clear language, and with engaging and often funny examples, Woo-Kyoung Ahn uses cutting-edge research to explain the mistakes we often make―and how to avoid them.”
							</AuthorQuote>
							<AuthorAuthor>
								―Gretchen Rubin, #1 New York Times bestselling author of The Happiness Project and The Four Tendencies
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								"Thinking 101 delivers a world-class tune-up for your brain. It will unclog your mental gears, restart your cognitive engine, and put you on the road to making smarter decisions.”
							</AuthorQuote>
							<AuthorAuthor>
								―Daniel H. Pink, #1 New York Times bestselling author of The Power of Regret, Drive, and A Whole New Mind
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								“There are other books on typical errors and biases of thinking. But Ahn’s is remarkable. Not only does she limit her coverage to just eight major such thinking problems, which allows her to deeply inform the reader about each with engaging, conversational prose, she also offers compelling, research-based ways to limit the problems’ unwanted impact. The result is a terrific one-two punch.”
							</AuthorQuote>
							<AuthorAuthor>
								―Robert Cialdini, author of Influence and Pre-Suasion
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								"Thinking 101 is a must-read―a smart and compellingly readable guide to cutting-edge research into how people think. Building from her popular Yale course, Professor Woo-kyoung Ahn shows how a better understanding of how our minds work can help us become smarter and wiser―and even kinder."
							</AuthorQuote>
							<AuthorAuthor>
								―Paul Bloom, Professor of Psychology, University of Toronto, Brooks and Suzanne Professor Emeritus of Psychology at Yale University, and the author of The Sweet Spot
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								"Ahn’s book is an absorbing, timely― and I think essential ― guide to how our minds go wrong and what we can do to think better. With lots of humorous stories and cautionary thinking tales, this terrifically-written book is a must-read for anyone who wants to understand and overcome the powerful yet invisible thinking traps that lead us astray."
							</AuthorQuote>
							<AuthorAuthor>
								―Laurie Santos, Professor of Psychology at Yale University and host of The Happiness Lab podcast
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								"Woo-kyoung Ahn uses wonderfully engaging examples to show how we can understand and improve our reasoning."
							</AuthorQuote>
							<AuthorAuthor>
								―Anna Rosling Rönnlund, co-author of Factfulness
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								"Thinking 101 breaks down when human thinking breaks down and unlike many other books on the topic, this one is accessible, engaging, and fun to read. Woo-kyoung Ahn's delightful sense of humor shines through, as she uses entertaining stories and examples to compellingly illustrate why thinking errors happen, why it matters, and what to do about it. The book is full of research-backed insights into how the mind works that newcomers to the field will find clear and understandable, but also has a number of gems that more advanced readers will appreciate."
							</AuthorQuote>
							<AuthorAuthor>
								―Danny Oppenheimer, Professor at Carnegie Mellon University and author of Democracy Despite Itself
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								"Thinking 101 provides evidence-based advice that has real potential to improve lives."
							</AuthorQuote>
							<AuthorAuthor>
								―Science
							</AuthorAuthor>
						</Author>

						<Author>
							<AuthorQuote>
								“Ahn excels at illustrating how psychological concepts manifest in everyday life, and her suggestions provide sensible techniques readers can use to push back against cognitive biases. This heady volume provides plenty of food for thought.”
							</AuthorQuote>
							<AuthorAuthor>
								―Publishers Weekly
							</AuthorAuthor>
						</Author>
					</VStack>
				</Box>
			</VStack>
		</PageLayout>
	)
}
export default Book;
