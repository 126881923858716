import React from 'react'
import { AiOutlineSound as SoundIcon } from 'react-icons/ai';
import { Center, Box, Icon } from '@chakra-ui/react';

const SoundButton = ({ fontSize = "20px", iconSize = "26px", children, ...props }) => {
	const audio = new Audio("/woo-kyoung-ahn.mp3");
	const play = () => {
		audio.play();
	}
	return (
		<Center as="button" onClick={play} p="16px 20px" {...props} title="Click to hear how to pronounce Woo-kyoung Ahn">
			<Box as="span" mr="8px" fontSize={fontSize}>{children}</Box>
			<Icon as={SoundIcon} boxSize={iconSize} />
		</Center>
	)
}
export default SoundButton;

