import React from 'react'
import { Box } from '@chakra-ui/react';
import NavBar from './NavBar';

const PageLayout = (props) => {
	return (
		<Box w="100%">
			<NavBar />
			<Box maxW="800px" m="0 auto" p="20px">
				{props.children}
			</Box>
		</Box>
	)
}
export default PageLayout;

